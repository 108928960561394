
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useMutation } from 'react-query';
import request from '@utils/request';
import { Spin, Card, Row, Col, Button } from 'antd';
import FileSaver from 'file-saver';
import domtoimage from 'dom-to-image';
import { formatNumber, awaitWrapper } from '@utils/helper';
import './style.less';

const PRODUCTS = [
  // { code: 'DHDEB', salePrice: 210000, salePercent: 54 },
  // { code: 'KL', salePrice: 210000, salePercent: 28 },
  // { code: 'AKKHND', salePrice: 210000, salePercent: 46 },
  // { code: 'DHHCEB', salePrice: 210000, salePercent: 54 },
  // { code: 'AKHL', salePrice: 210000, salePercent: 46 },
  // { code: 'AKTHMH', salePrice: 210000, salePercent: 46 },
  // { code: 'AKHV', salePrice: 210000, salePercent: 46 },
  // { code: 'DVHSEB', salePrice: 210000, salePercent: 54 },
  // { code: 'KLTT', salePrice: 210000, salePercent: 46 },
  // { code: 'AKT', salePrice: 210000, salePercent: 46 },
  { code: 'DPH', salePrice: 390000, salePercent: 52 },
  { code: 'DSXCT', salePrice: 390000, salePercent: 51 },
  { code: 'DHOTT', salePrice: 390000, salePercent: 48 },
  { code: 'DSTCT', salePrice: 390000, salePercent: 48 },
  { code: 'DTIBDN', salePrice: 390000, salePercent: 48 },
  { code: 'DXBDN', salePrice: 390000, salePercent: 48 },
  { code: 'DHNT', salePrice: 390000, salePercent: 52 },
  { code: 'DMDHP', salePrice: 390000, salePercent: 51 },
  { code: 'DTOCTK', salePrice: 390000, salePercent: 58 },
  { code: 'DMDDN', salePrice: 390000, salePercent: 51 },
  { code: 'DTSTND', salePrice: 390000, salePercent: 52 },
  { code: 'DSXNV', salePrice: 390000, salePercent: 51 },
  { code: 'DMDT', salePrice: 390000, salePercent: 48 },
  { code: 'DTHTIX', salePrice: 390000, salePercent: 52 },
  { code: 'DHX', salePrice: 390000, salePercent: 51 },
  { code: 'DTOHTD', salePrice: 390000, salePercent: 54 },
  { code: 'DTBD', salePrice: 390000, salePercent: 48 },
  // { code: 'DTIBD', salePrice: 390000, salePercent: 48 },
  { code: 'DHTP', salePrice: 550000, salePercent: 35 },
  { code: 'DTXDX', salePrice: 550000, salePercent: 33 },
  { code: 'DTHNX', salePrice: 550000, salePercent: 33 },
  { code: 'DTOHA', salePrice: 550000, salePercent: 40 },
  { code: 'DCDC', salePrice: 550000, salePercent: 33 },
  { code: 'DTBHO', salePrice: 550000, salePercent: 33 },
  { code: 'DTXDD', salePrice: 550000, salePercent: 33 },
  { code: 'DTBV', salePrice: 550000, salePercent: 38 },
  { code: 'DTQH', salePrice: 550000, salePercent: 36 },
  { code: 'DTOBN', salePrice: 550000, salePercent: 38 },
  { code: 'DTHXX', salePrice: 550000, salePercent: 40 },
  { code: 'DTVH', salePrice: 550000, salePercent: 33 },
  { code: 'DLLLND', salePrice: 550000, salePercent: 36 },
  { code: 'DLLLNDO', salePrice: 550000, salePercent: 36 },
  { code: 'DTOHL', salePrice: 550000, salePercent: 33 },
  { code: 'DCHV', salePrice: 550000, salePercent: 40 },
  { code: 'DTMML', salePrice: 550000, salePercent: 43 },
  { code: 'DSND', salePrice: 550000, salePercent: 33 },
  { code: 'DTBXC', salePrice: 550000, salePercent: 40 },
  { code: 'DHTV', salePrice: 550000, salePercent: 35 },
  { code: 'DTLKX', salePrice: 550000, salePercent: 30 },
  { code: 'DTMLT', salePrice: 550000, salePercent: 38 },
  { code: 'DTOBDO', salePrice: 550000, salePercent: 38 },
  { code: 'DTBH', salePrice: 550000, salePercent: 38 },
  { code: 'DLAT', salePrice: 550000, salePercent: 38 },
  { code: 'DLMDX', salePrice: 550000, salePercent: 33 },
  { code: 'DHTSX', salePrice: 550000, salePercent: 35 },
  { code: 'DLHX', salePrice: 550000, salePercent: 33 },
  { code: 'DTMDN', salePrice: 550000, salePercent: 33 },
  { code: 'DHTMD', salePrice: 550000, salePercent: 35 },
  { code: 'DMDTD', salePrice: 550000, salePercent: 30 },
  { code: 'DHHDC', salePrice: 550000, salePercent: 33 },
  { code: 'DTBVSN', salePrice: 550000, salePercent: 38 },
  { code: 'DTBC', salePrice: 550000, salePercent: 38 },
  { code: 'DTBSNV', salePrice: 550000, salePercent: 35 },
  { code: 'DTHND', salePrice: 550000, salePercent: 33 },
  { code: 'DLTTCX', salePrice: 550000, salePercent: 40 },
  { code: 'DTXNT', salePrice: 550000, salePercent: 30 },
  { code: 'DTHVDC', salePrice: 550000, salePercent: 33 },
  { code: 'DMDDC', salePrice: 550000, salePercent: 33 },
  { code: 'DLLHD', salePrice: 550000, salePercent: 33 },
  { code: 'DTTND', salePrice: 550000, salePercent: 33 },
  { code: 'DTBT', salePrice: 550000, salePercent: 38 },
  { code: 'DTBS', salePrice: 550000, salePercent: 40 },
  { code: 'DTCMD', salePrice: 550000, salePercent: 36 },
  { code: 'DTCTX', salePrice: 550000, salePercent: 33 },
  { code: 'DTBXB', salePrice: 550000, salePercent: 38 },
  { code: 'DSXND', salePrice: 550000, salePercent: 33 },
  { code: 'DHDSH', salePrice: 550000, salePercent: 33 },
  { code: 'DTXLDC', salePrice: 550000, salePercent: 33 },
  { code: 'DMLEN', salePrice: 550000, salePercent: 30 },
  { code: 'DTSXNN', salePrice: 550000, salePercent: 33 },
  { code: 'DLAH', salePrice: 550000, salePercent: 38 },
  { code: 'DTNXHH', salePrice: 550000, salePercent: 32 },
  { code: 'DLMDH', salePrice: 550000, salePercent: 33 },
  { code: 'DHTNX', salePrice: 550000, salePercent: 33 },
  { code: 'DMDTDO', salePrice: 550000, salePercent: 33 },
  { code: 'DTHXD', salePrice: 550000, salePercent: 40 },
  { code: 'DSBX', salePrice: 550000, salePercent: 30 },
  { code: 'DSDND', salePrice: 550000, salePercent: 33 },
  { code: 'DLLHX', salePrice: 550000, salePercent: 33 },
  { code: 'DHVNN', salePrice: 550000, salePercent: 33 },
  { code: 'DTXDC', salePrice: 550000, salePercent: 33 },
  // { code: 'DTHNH', salePrice: 550000, salePercent: 33 },
  // { code: 'DTSXL', salePrice: 550000, salePercent: 33 },
  // { code: 'DTMHTX', salePrice: 550000, salePercent: 40 },
  // { code: 'DTHHD', salePrice: 550000, salePercent: 33 },
  { code: 'DTODH', salePrice: 750000, salePercent: 13 },
  { code: 'DMDHTD', salePrice: 750000, salePercent: 15 },
  { code: 'DTOMD', salePrice: 750000, salePercent: 13 },
  { code: 'DTOTND', salePrice: 750000, salePercent: 13 },
  { code: 'DHTADD', salePrice: 750000, salePercent: 15 },
  { code: 'DCDDC', salePrice: 750000, salePercent: 9 },
  { code: 'DLLXX', salePrice: 750000, salePercent: 9 },
  { code: 'DLLLD', salePrice: 750000, salePercent: 9 },
  { code: 'DLTTHV', salePrice: 750000, salePercent: 22 },
  { code: 'DLTTSNV', salePrice: 750000, salePercent: 22 },
  { code: 'DLTTMDT', salePrice: 750000, salePercent: 22 },
  { code: 'DLTTSCNX', salePrice: 750000, salePercent: 22 },
  { code: 'DLTTMDTNV', salePrice: 750000, salePercent: 22 },
  { code: 'DLTTSN', salePrice: 750000, salePercent: 22 },
  { code: 'DLTTCNC', salePrice: 750000, salePercent: 22 },
  { code: 'DLTTTTX', salePrice: 750000, salePercent: 22 },
  { code: 'DLTTCNX', salePrice: 750000, salePercent: 22 },
  { code: 'DLTTHD', salePrice: 750000, salePercent: 42 },
  { code: 'DLTTMDX', salePrice: 750000, salePercent: 22 },
  { code: 'DLTTLS', salePrice: 750000, salePercent: 22 },
  { code: 'DLTTXHNU', salePrice: 750000, salePercent: 22 },
  { code: 'DLTTLT', salePrice: 750000, salePercent: 22 },
  { code: 'DLTTSTX', salePrice: 750000, salePercent: 22 },
  { code: 'DLTTSD', salePrice: 750000, salePercent: 22 },
  { code: 'DLTTNT', salePrice: 750000, salePercent: 22 },
  { code: 'DLTTXHH', salePrice: 750000, salePercent: 22 },
  { code: 'DLTTSTH', salePrice: 750000, salePercent: 22 },
  { code: 'DLTTHLNV', salePrice: 750000, salePercent: 22 },
  { code: 'DLTTTTD', salePrice: 750000, salePercent: 22 },
  { code: 'DLTTSBC', salePrice: 750000, salePercent: 22 },
  { code: 'DTHMH', salePrice: 750000, salePercent: 9 },
  { code: 'DHTGH', salePrice: 750000, salePercent: 15 },
  { code: 'DTXDC', salePrice: 550000, salePercent: 33 },
  { code: 'DTMDCM', salePrice: 750000, salePercent: 13 },
]


function Campaign(props) {
  const [loading, setLoading] = useState(false);
  const [productList, setProductList] = useState([]);
  const [dataSource, setDataSource] = useState([]);
  const [showBanner, setShowBanner] = useState(false);
  const [mainPageIndex, setMainPageIndex] = useState(0);
  const [isDownloading, setIsDownloading] = useState(false);
  let tDoDownloadAll = null;
  const { isLoading, mutate: getProductList } = useMutation(() => {
    return request.get('v1/product-meta');
  },
    {
      onSuccess: (response) => {
        setProductList(response.data);
        initData(response.data);
      },
    });
  useEffect(() => {
    getProductList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  const initData = (productList) => {
    const data = [];
    PRODUCTS.forEach(p => {
      const sku = p.code.toLowerCase();
      const item = productList.find(x => x.pSku === sku);
      if (item) {
        // const salePercent = 100 - Math.round(p.salePrice / item.price * 100);
        // if(salePercent >= 40){
        const newItem = {
          ...item,
          salePrice: p.salePrice,
          salePercent: p.salePercent,
        }
        if(!item.image && p.url){
          newItem.image = p.url;
        }
        data.push(newItem);
        // }
      }
    })
    setDataSource(data);
  }
  const updateWoo = async (product = {}, resetPrice = false) => {
    if (!product.wooProductId) return false;
    const getVariationData = {
      url: `products/${product.wooProductId}`,
      method: 'get'
    }
    const [gErr, gRes] = await awaitWrapper(request.post('/v1/wp-woo', getVariationData));
    if (!gErr) {
      const wooProduct = gRes.data;
      const variations = wooProduct.variations || [];
      for (let i = 0; i < variations.length; i++) {
        const data = {
          url: `products/${product.wooProductId}/variations/${variations[i]}`,
          method: 'put',
          data: {
            sale_price: resetPrice ? `${product.price}` : `${product.salePrice}`,
          }
        }
        await awaitWrapper(request.post('/v1/wp-woo', data));
      }
    }
  }

  const handleUpdate = async (resetPrice = false) => {
    setLoading(true);
    const pList = [];
    PRODUCTS.forEach(p => {
      const sku = p.code.toLowerCase();
      const item = productList.find(x => x.pSku === sku);
      if (item) {
        pList.push({
          ...item,
          salePrice: p.salePrice
        })
      }
    })
    for (let i = 0; i < pList.length; i++) {
      await updateWoo(pList[i], resetPrice);
    }
    setLoading(false);
  }
  const handleImageLoaded = () => {
    if (isDownloading) {
      handleDownload();
      clearTimeout(tDoDownloadAll);
      const nextTick = Math.round(Math.random() * 1000 + 1000);
      tDoDownloadAll = setTimeout(() => { doDownloadAll(); }, nextTick);
    }
    console.log('image loaded');
  }
  const handleDownloadAll = async () => {
    if (isDownloading) {
      setIsDownloading(false);
    } else {
      setIsDownloading(true);
      setMainPageIndex(67);
      doDownloadAll();
    }
  }
  const doDownloadAll = async () => {
    if (mainPageIndex >= PRODUCTS.length) {
      setIsDownloading(false);
      return;
    }
    if (mainPageIndex < PRODUCTS.length - 1) {
      let ind = mainPageIndex + 1;
      const p = PRODUCTS[ind];
      if (!p) ind += 1;
      else {
        const sku = p.code.toLowerCase();
        const item = productList.find(x => x.pSku === sku);
        if (!item.image) {
          ind += 1;
        }
      }

      setMainPageIndex(ind);
    } else {
      setIsDownloading(false);
    }
    return;
  }
  const handleDownload = async () => {
    var node = document.querySelector('.productBanner');
    const name = node.getAttribute('data-name');
    // const name = 'TEST';
    domtoimage.toBlob(node)
      .then(function (blob) {
        FileSaver.saveAs(blob, `${name}.png`);
      });
  }
  const handleShowBanner = () => {
    if (!showBanner) {
      setMainPageIndex(0);
    }
    setShowBanner(!showBanner);
  }
  const handleClickItem = (itemInd => {
    setMainPageIndex(itemInd);
    if (!showBanner) setShowBanner(true);
  })
  const renderProductDetail = (item, ind) => {
    // const sku = p.code.toLowerCase();
    // const item = productList.find(x => x.pSku === sku);
    // if (!item) return '';
    const cover = (
      <p>
        <img alt={item.pName} src={item.image} />
      </p>
    );
    return (
      <Col sm={6} style={{ marginBottom: 16 }} key={item.pSku}>
        <Card className='productItem' hoverable cover={cover} onClick={() => handleClickItem(ind)}>
          <div style={{ height: 42 }}>{item.pName} - {item.pSku}</div>
          <div style={{ fontSize: 20 }}>{formatNumber(item.price)}{` => `}<b>{formatNumber(item.salePrice)}</b></div>
        </Card>
      </Col>
    )
  }
  const renderProductBanner = () => {
    if (mainPageIndex < 0) return '';
    // const p = PRODUCTS[mainPageIndex];
    // if (!p) return '';
    // const sku = p.code.toLowerCase();
    // const item = productList.find(x => x.pSku === sku);
    const item = dataSource[mainPageIndex];
    if (!item) return '';
    const orgPrice = item.price / 1000;
    const salePrice = item.salePrice / 1000;
    const imageSrc = item.image ? item.image.replace('https://otina.n4u.vn/', 'https://otina-proxy.n4u.vn/') : '';
    let minorImage = imageSrc;
    if (item.gallery && item.gallery.indexOf(',') > 0) {
      const galleries = item.gallery.split(',');
      minorImage = galleries[0].replace('https://otina.n4u.vn/', 'https://otina-proxy.n4u.vn/');
    }
    let sku = item.pSku.toUpperCase();
    const name = item.pName;
    let skuLink = sku;
    if(item.wooProductId){
      skuLink = <a target="_blank" rel="noopener noreferrer" href={`https://otina.n4u.vn/wp-admin/post.php?post=${item.wooProductId}&action=edit`}>{sku}</a>;
    }
    return (
      <div className='productBanner' data-percent={item.salePercent} data-name={`${salePrice}-20250102-${sku}`}>
        <div className='imageFrame' style={{ backgroundImage: `url(${imageSrc})` }}></div>
        <img className='imageSrc' alt="MainImage" src={imageSrc} onLoad={handleImageLoaded} />
        <div className='priceArea'>
          <div className='price'>
            <span className='orgPrice'>{`${orgPrice}K`}</span>
            <span className='salePrice'>{salePrice}K</span>
          </div>
        </div>
        <div className='minorImage' style={{ backgroundImage: `url(${minorImage})` }}></div>
        <div className='sku'>{skuLink}</div>
        <div className='salePercent'>{item.salePercent}% OFF</div>
        <div className='name'>{name}</div>
        <div className='code'>D{mainPageIndex + 1}</div>
      </div>
    )
  }
  return (
    <Spin spinning={isLoading}>
      <Row gutter={16}>
        <Col sm={12}>
          <h2>Sale 20250102 - 20250130</h2>
        </Col>
        <Col sm={6}>
          <Button onClick={handleShowBanner}>{showBanner ? 'Hide' : 'Show'}</Button>
          {showBanner &&
            <Button onClick={handleDownloadAll}>{isDownloading ? `Downloading (${mainPageIndex + 1}/${PRODUCTS.length})` : `Download All`}</Button>
          }
        </Col>
        <Col sm={6}>
          <Button loading={loading} disabled={loading} onClick={() => handleUpdate(false)}>Update Price</Button>
          <Button loading={loading} disabled={loading} onClick={() => handleUpdate(true)}>Reset Price</Button>
        </Col>
      </Row>
      {showBanner && (<Row gutter={16}>
        <Col sm={2} align="right">
          <Button style={{ marginTop: 20 }} disabled={mainPageIndex < 1} onClick={() => setMainPageIndex(mainPageIndex - 1)}>Previous</Button>
          <Button onClick={handleDownload}>Download</Button>
        </Col>
        <Col sm={20}>
          <div>
            {renderProductBanner()}
          </div>
        </Col>
        <Col sm={2}>
          <Button style={{ marginTop: 20 }} disabled={mainPageIndex > PRODUCTS.length - 2} onClick={() => setMainPageIndex(mainPageIndex + 1)}>Next</Button>
        </Col>
      </Row>)}
      <Row gutter={16}>
        {dataSource.map(renderProductDetail)}
      </Row>
    </Spin>
  )
}

Campaign.propTypes = {
  dataSource: PropTypes.array,
  categoryList: PropTypes.array,
  loading: PropTypes.bool,
};

Campaign.defaultProps = {
  dataSource: [],
  categoryList: [],
  loading: false,
};

export default Campaign;
